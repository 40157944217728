import { TFaq } from "@page-builder/ts/generated";
import React, { useState } from "react";
import { iconsLookup } from "./iconsLookup";
import styles from "./styles.module.scss";
import { replacePlaceholders } from "@lib/sharedUtils";

const Faq = ({ props = {} }: { props?: TFaq["props"] }) => {
    const { faqs, iconType, title } = props;
    const [faqState, setFaqState] = useState(faqs);

    const handleQuestionClick = (idx: number) => {
        setFaqState(
            faqState?.map((faq, index) => {
                if (index === idx) {
                    return {
                        ...faq,
                        showAnswer: !faq.showAnswer,
                    };
                }
                return faq;
            }),
        );
    };

    const resolveIcon = (showAnswer: boolean) => {
        const upOrDown = showAnswer ? "up" : "down";
        return iconsLookup[iconType ?? "filled"][upOrDown];
    };

    return (
        <section className={`${styles["faqs-container"]}`}>
            {title && (
                <div
                    className={styles.title}
                    dangerouslySetInnerHTML={{ __html: title }}
                />
            )}
            {faqState?.map((faq, idx) => (
                <div key={idx}>
                    <div
                        className={`${styles["questions-container"]}`}
                        onClick={() => handleQuestionClick(idx)}
                    >
                        <>
                            {replacePlaceholders(faq.question as string)}
                            <div className="w-[35px] sm:w-[25px] h-[35px] sm:h-[25px] ml-3">
                                {resolveIcon(faq.showAnswer as boolean)}
                            </div>
                        </>
                    </div>
                    {faq.showAnswer && (
                        <div className={`${styles["answers-container"]}`}>
                            {replacePlaceholders(faq.answer as string)}
                        </div>
                    )}
                </div>
            ))}
        </section>
    );
};

export default Faq;
